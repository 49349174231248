import React, { useEffect, useState } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikTime from '../../components/formik/FormikTime';
import moment from 'moment';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';

const AddCalenderTime = ({ isTimeModalOpen, setIsTimeModalOpen,fetchCalenderTimes }) => {
    const [times, setTimes] = useState(null);
    const [actionType, setActionType] = useState('');

    const convertToISO = (timeString, date) => {
        if (!timeString) return null;

        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        const localDate = new Date(date);
        localDate.setHours(hours, minutes, seconds, 0);
        const isoString = localDate.toISOString();

        return isoString;
    };

    const [initialValues, setInitialValues] = useState({
        workStartTime: '',
        workEndTime: '',
        lunchStartTime: '',
        lunchEndTime: '',
    });

    const fetchCalenderData = async () => {
        try {
            const resp = await authAxiosInstance.get(`/calenderTimes`);
            const fetchedTimes = resp?.data?.data[0];
            setTimes(fetchedTimes);

            if (fetchedTimes) {
                const today = moment().format('YYYY-MM-DD');
                setInitialValues({
                    workStartTime: moment(
                        convertToISO(fetchedTimes.workStartTime, today)
                    ).toDate(),
                    workEndTime: moment(
                        convertToISO(fetchedTimes.workEndTime, today)
                    ).toDate(),
                    lunchStartTime: moment(
                        convertToISO(fetchedTimes.lunchStartTime, today)
                    ).toDate(),
                    lunchEndTime: moment(
                        convertToISO(fetchedTimes.lunchEndTime, today)
                    ).toDate(),
                });
            }
        } catch (error) {
            console.error('Error fetching calendar times:', error);
        }
    };
    useEffect(() => {
        if(isTimeModalOpen){
            fetchCalenderData();
        }
    }, [isTimeModalOpen]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit: async (values, { resetForm }) => {
            try {
                const startWorkTime = moment(values?.workStartTime).format(
                    'HH:mm:ss'
                );
                const endWorkTime = moment(values?.workEndTime).format(
                    'HH:mm:ss'
                );
                const startLunchTime = moment(values?.lunchStartTime).format(
                    'HH:mm:ss'
                );
                const endLunchTime = moment(values?.lunchEndTime).format(
                    'HH:mm:ss'
                );

                if (actionType === 'submit') {
                    const obj = {
                        workStartTime: startWorkTime,
                        workEndTime: endWorkTime,
                        lunchStartTime: startLunchTime,
                        lunchEndTime: endLunchTime,
                    };
                    const resp = await authAxiosInstance.post(
                        `/calenderTimes`,
                        obj
                    );
                    if (resp?.data?.success) {
                        // fetchCalenderData();
                        fetchCalenderTimes();
                    }
                } else if (actionType === 'edit') {
                    const editObj = {
                        ...times,
                        workStartTime: startWorkTime,
                        workEndTime: endWorkTime,
                        lunchStartTime: startLunchTime,
                        lunchEndTime: endLunchTime,
                    };
                    const resp = await authAxiosInstance.patch(
                        `/calenderTimes`,
                        editObj
                    );
                    if (resp?.data?.success) {
                        // fetchCalenderData();
                        fetchCalenderTimes();
                    }
                }
                toast.success(
                    actionType === 'edit'
                        ? 'Calendar Time updated'
                        : 'Calendar Time Added'
                );
                resetForm();
                setIsTimeModalOpen(false);
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('Failed to create times');
            }
        },
    });

    return (
        <ModalBasic
            modalOpen={isTimeModalOpen}
            setModalOpen={setIsTimeModalOpen}
            title="Add Working Hour and Lunch Time"
        >
            <form
                className="flex flex-col gap-4 p-4"
                onSubmit={formik.handleSubmit}
            >
                <div className="flex flex-col md:flex-row items-center gap-4">
                    <div className="w-full">
                        <FormikTime
                            formik={formik}
                            label="Work Start Time"
                            name="workStartTime"
                        />
                    </div>
                    <div className="w-full">
                        <FormikTime
                            formik={formik}
                            label="Work End Time"
                            name="workEndTime"
                        />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row items-center gap-4">
                    <div className="w-full">
                        <FormikTime
                            formik={formik}
                            label="Lunch Start Time"
                            name="lunchStartTime"
                        />
                    </div>
                    <div className="w-full">
                        <FormikTime
                            formik={formik}
                            label="Lunch End Time"
                            name="lunchEndTime"
                        />
                    </div>
                </div>
                <div>
                    {times ? (
                        <PrimaryButton
                            type="submit"
                            onClick={(e) => {
                                e.stopPropagation();
                                setActionType('edit');
                            }}
                        >
                            Edit Times
                        </PrimaryButton>
                    ) : (
                        <PrimaryButton
                            type="submit"
                            onClick={(e) => {
                                e.stopPropagation();
                                setActionType('submit');
                            }}
                        >
                            Add Times
                        </PrimaryButton>
                    )}
                </div>
            </form>
        </ModalBasic>
    );
};

export default AddCalenderTime;
