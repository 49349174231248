import React from 'react';

const AppVersion = () => {
    return (
        <span className="text-gray-300 text-xs text-right">
            {process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : ''}
        </span>
    );
};

export default AppVersion;
