export const customIDs = {
    customer_calling: '1408947000013655001', //cf_calling
    // customer_title: "466597000008642679",
    // customer_priority: "466597000014141047",

    // invoice_chalan_number: "466597000014434058",
    // invoice_item_id: "466597000000387023",
    invoice_tracking_id: '1408947000000599013', //cf_tracking_id
    invoice_rate_calculation: '1408947000013655033', //cf_rate_calculation
    invoice_statment_period: '1408947000002362704', //cf_statement_period
    invoice_po_report_url: '1408947000013655041', //cf_po_report_url
    invoice_bill_report_url: '1408947000013655045', //cf_bill_report_url
    invoice_tds_tax_id: '1408947000000065041', //tds_tax
    invoice_item_account_id: '1408947000000000388', //.........
    invoice_mobile_no: '1408947000013884024', //cf_mobile_no

    purchase_item_account_id: '1408947000000034003', //.......
    purchase_item_qty_taka: '1408947000000112093', //cf_qty_taka
    purchase_item_commission: '1408947000000112113', //cf_commission
    purchase_item_vendorName: '1408947000001217019', //cf_vendor_name
    purchase_custom_buyerName: '1408947000000257104', //cf_buyer_name
    purchase_system_generated: '1408947000023782011',
    purchase_custom_qty_taka: '1408947000000257108', //cf_qty_taka
    purchase_custom_quality: '1408947000000257116', //cf_quality_name
    purchase_custom_rate: '1408947000000257112', //cf_rate
    purchase_custom_split: '1408947000013655053', //cf_is_split
    purchase_custom_payment_terms: '1408947000013655069', //cf_copy_payment_terms
    purchase_payment_complaint: '1408947000012762032', //cf_generate_complaint
    purchase_custom_complaint_note: '1408947000012762038', //cf_complaint_note
    purchase_custom_tag_2: '1408947000012762042', //cf_complaint_status
    purchase_custom_solve_note: '1408947000012762048', //cf_solve_note
    purchase_custom_complaint_open_date: '1408947000013655057', //cf_complaint_open_date
    purchase_custom_complaint_cloze_date: '1408947000013655061', //cf_complaint_cloze_date
    purchase_custom_has_complaint: '1408947000013655065', //cf_has_complaint
    purchase_custom_commission_collection: '1408947000013548001', //cf_commission_collection
    purchase_custom_vendor_commision: '1408947000013676113', //cf_vendor_collection
    purchase_item_unit_rate: '1408947000000112103',
    purchase_custom_field_qty_taka: '1408947000000257108', //cf_qty_taka
    purchase_item_custom_field_qty_taka: '1408947000000112093', //cf_qty_taka
    purchase_order_customer_field_po_note: '1408947000017183537', //cf_qty_taka

    purchase_bill_custom_customer_name: '1408947000013676001', //cf_customer_name
    customer_chalan_name: '1408947000012762066', //cf_chalan_name
    credit_q1: '1408947000013655013',
    credit_q2: '1408947000013655017',
    credit_q3: '1408947000013655021',
    credit_q4: '1408947000013655025',
};
