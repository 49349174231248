import React, { useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import {
    generateOptions,
    generateOptionsForPaymentTerms,
    generateQualityLabel,
    percentOf,
} from '../../utils/Utils';
import {
    fetchContacts,
    getContacts,
} from '../../app/reducers/Contacts/contactsSlice';
import { fetchCustomers } from '../../app/reducers/Customer/customerSlice';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import {
    fetchPurchaseOrders,
    createPurchaseOrder,
    fetchPurchaseOrder,
    getPurchaseOrder,
    updatePurchaseOrder,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import {
    fetchQualities,
    getQuality,
} from '../../app/reducers/Quality/qualitySlice';
import moment from 'moment';
import * as Yup from 'yup';
import {
    fetchPaymentTerms,
    getPaymentTerm,
} from '../../app/reducers/PaymentTerms/paymentTermSLice';
import { ClipLoader } from 'react-spinners';
import { LABEL_VALUES } from '../../utils/dropdownOptions';
import { customIDs } from '../../utils/customIds';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { roundTo } from 'round-to';

const CreatePurchaseOrder = () => {
    const dispatch = useDispatch();
    const { quality, loading: qualityLoading } = useSelector(getQuality);
    const { loading } = useSelector(getPurchaseOrder);

    const formik = useFormik({
        initialValues: {
            poNumber: 'PO-',
            isVendor: 'no',
            customer: '',
            gstNumber: '',
            quality: '',
            buyerName: '',
            qtyTaka: 0,
            vendor: '',
            vendorName: '',
            payment_terms_label: '',
            paymentTerm: '',
            rate: 0,
            commission: 0,
            quantity: '',
            customer_commission: 0,
            vendor_commission: 0,
            date: new Date(),
            expectedDeliveryDate: '',
            paymentTermPer: '',
        },
        validationSchema: Yup.object({
            poNumber: Yup.string().required(),
            customer: Yup.string().required(),
            quality: Yup.string().required(),
            paymentTerm: Yup.string().required(),
            buyerName: Yup.string(),
            vendorName: Yup.string(),
            isVendor: Yup.string().required(),
            vendor_commission: Yup.string(),
            payment_terms_label: Yup.string(),
            qtyTaka: Yup.number().min(1).required(),
            vendor: Yup.string().required(),
            rate: Yup.number().min(1).required(),
            commission: Yup.number().required(),
            date: Yup.mixed().required(),
            quantity: Yup.number().required(),
            paymentTermPer: Yup.string(),
        }),
        onSubmit: async (values) => {
            console.log(values);
            const updatedRate = (
                parseFloat(values.rate) +
                parseFloat(
                    percentOf(parseFloat(values.paymentTermPer), values.rate)
                )
            ).toFixed(2);
            console.log(typeof updatedRate);
            const newRate = roundTo(parseFloat(updatedRate), 2);
            console.log(newRate);
            const commission =
                values.isVendor == 'vendor'
                    ? parseFloat(values.vendor_commission)
                    : parseFloat(values.customer_commission);
            let data = {
                vendor_id: values.vendor.split('/')[0],
                payment_terms: parseInt(values.paymentTerm.split('/')[0]),
                payment_terms_label: values.payment_terms_label,
                place_of_supply: values.place_of_supply,
                date: moment(values.date).format('YYYY-MM-DD'),
                delivery_date: values.expectedDeliveryDate
                    ? moment(values.expectedDeliveryDate).format('YYYY-MM-DD')
                    : '',
                delivery_customer_id: values.customer,
                line_items: [
                    {
                        description: values.quality,
                        account_id: customIDs.purchase_item_account_id,
                        // rate: newRate,
                        rate: (newRate * values.qtyTaka * commission) / 100,
                        quantity: values.quantity,
                        item_custom_fields: [
                            {
                                customfield_id:
                                    customIDs.purchase_item_qty_taka,
                                value: values.qtyTaka,
                            },
                            {
                                customfield_id:
                                    customIDs.purchase_item_unit_rate,
                                value: newRate,
                            },
                            {
                                customfield_id:
                                    customIDs.purchase_item_commission,
                                value: commission,
                            },
                            {
                                customfield_id:
                                    customIDs.purchase_item_vendorName,
                                value: values.vendorName,
                            },
                        ],
                    },
                ],
                custom_fields: [
                    {
                        customfield_id: customIDs.purchase_system_generated,
                        value: 'yes',
                    },
                    {
                        customfield_id: customIDs.purchase_custom_buyerName,
                        value: values.buyerName,
                    },
                    {
                        customfield_id: customIDs.purchase_custom_qty_taka,
                        value: values.qtyTaka,
                    },
                    {
                        customfield_id: customIDs.purchase_custom_quality,
                        value: values.quality,
                    },
                    {
                        customfield_id: customIDs.purchase_custom_rate,
                        value: newRate,
                    },
                    {
                        customfield_id:
                            customIDs.purchase_custom_commission_collection,
                        value: values.isVendor,
                    },
                    {
                        customfield_id:
                            customIDs.purchase_custom_vendor_commision, //cf_vendor_commission
                        value: values.vendor_commission
                            ? values.vendor_commission
                            : 0,
                    },
                ],
            };
            console.log(data, 'onSubmit Data');
            const purchaseOrder = await dispatch(createPurchaseOrder(data));
            if (purchaseOrder?.payload) {
                dispatch(
                    updatePurchaseOrder({
                        id: purchaseOrder?.payload?.purchaseorder
                            .purchaseorder_id,
                        status: 'open',
                    })
                );
            }
            formik.resetForm();
            formik.setFieldValue('vendor', '');
            formik.setFieldValue('customer', '');
            const action = await dispatch(fetchPurchaseOrders());
            if (action.payload?.purchaseorders) {
                formik.setFieldValue(
                    'poNumber',
                    `PO-${
                        parseInt(
                            action.payload?.purchaseorders[0].purchaseorder_number.split(
                                '-'
                            )[1]
                        ) + 1
                    }`
                );
            }
        },
    });
    const { paymentTerms } = useSelector(getPaymentTerm);

    useEffect(async () => {
        dispatch(fetchContacts({ contact_type: 'vendor' }));
        dispatch(fetchCustomers({ contact_type: 'customer' }));
        dispatch(fetchPaymentTerms());
        dispatch(fetchQualities({ limit: 500 }));
        const action = await dispatch(fetchPurchaseOrders());
        if (action.payload?.purchaseorders) {
            formik.setFieldValue(
                'poNumber',
                `PO-${
                    parseInt(
                        action.payload?.purchaseorders[0].purchaseorder_number.split(
                            '-'
                        )[1]
                    ) + 1
                }`
            );
        }
    }, []);

    const paymentTermData = useMemo(
        () => (paymentTerms?.docs ? paymentTerms.docs : []),
        [paymentTerms]
    );
    return (
        <div className="p-4">
            {loading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4 w-full"
                >
                    <h1 className="text-lg font-bold">Create Purchase Order</h1>
                    <div className=" grid md:grid-cols-2 gap-2">
                        <FormikAsyncSelect
                            name="vendor"
                            label="Vendor"
                            formik={formik}
                            getOptions={async (value) => {
                                const action = await dispatch(
                                    fetchContacts({
                                        search: value,
                                        contact_type: 'vendor',
                                    })
                                );
                                const serverResp = action.payload.contacts.map(
                                    (ele) => ({
                                        label: `${ele.contact_name}`,
                                        value: `${ele.contact_id}/${ele.contact_name}/${ele?.cf_commission}`,
                                    })
                                );
                                return serverResp;
                            }}
                            onChange={(option) => {
                                const id = option.value.split('/')[0];
                                const vendor_name = option.value.split('/')[1];
                                const vendor_commission =
                                    option.value.split('/')[2];
                                formik.setFieldValue('vendorName', vendor_name);
                                // formik.setFieldValue("vendor_commission", vendor_commission);
                                formik.setFieldValue('vendor', id);
                            }}
                        />
                        <FormikAsyncSelect
                            name="customer"
                            label="Customer"
                            formik={formik}
                            getOptions={async (value) => {
                                const action = await dispatch(
                                    fetchCustomers({
                                        search: value,
                                        contact_type: 'customer',
                                    })
                                );
                                const serverResp = action.payload.contacts.map(
                                    (ele) => ({
                                        label: ele.contact_name,
                                        value: `${ele.contact_id}`,
                                    })
                                );
                                return serverResp;
                            }}
                            onChange={async (option) => {
                                console.log(option.value);
                                const id = option.value;
                                const customerDetail =
                                    await authAxiosInstance.get(
                                        `/purchase-orders/contacts/${id}`
                                    );
                                console.log(customerDetail, 'checkig');
                                formik.setFieldValue('buyerName', option.label);
                                formik.setFieldValue(
                                    'gstNumber',
                                    customerDetail?.data?.data?.contact.cf_gstin
                                );
                                formik.setFieldValue('customer', id);
                            }}
                        />
                    </div>

                    {formik.values.gstNumber && (
                        <FormikInputGroup
                            readOnly
                            name="gstNumber"
                            label="GST Number"
                            formik={formik}
                        />
                    )}
                    <FormikInputDateGroup
                        name="date"
                        label="Date"
                        formik={formik}
                        required
                    />

                    <FormikInputGroup
                        name="poNumber"
                        label="Purchase Order#"
                        formik={formik}
                        required
                        readOnly
                    />
                    <div className="flex flex-row gap-4">
                        <FormikInputDateGroup
                            name="expectedDeliveryDate"
                            label="Expected Delivery Date"
                            formik={formik}
                        />
                        <FormikSelectGroup
                            formik={formik}
                            label="Payment Terms"
                            name={`paymentTerm`}
                            onChange={(selectedOption) => {
                                console.log(selectedOption);
                                let findPaymentData = paymentTermData.find(
                                    (d) => d.name == selectedOption.label
                                );
                                formik.setFieldValue(
                                    'payment_terms_label',
                                    selectedOption.value.split('/')[1]
                                );
                                formik.setFieldValue(
                                    'paymentTerm',
                                    selectedOption.value
                                );
                                formik.setFieldValue(
                                    'paymentTermPer',
                                    findPaymentData?.percentage
                                        ? findPaymentData?.percentage
                                        : '0'
                                );
                            }}
                            options={generateOptionsForPaymentTerms({
                                array: paymentTermData,
                                labelField: 'name',
                                valueField: 'value',
                            })}
                            required
                        />
                    </div>

                    <div className="flex flex-row gap-4">
                        <FormikInputGroup
                            name="qtyTaka"
                            fullWidth={true}
                            type="number"
                            label="QTY Taka"
                            formik={formik}
                            required
                        />
                        <FormikInputGroup
                            name="rate"
                            fullWidth={true}
                            type="number"
                            label="RATE"
                            formik={formik}
                            required
                        />
                    </div>
                    <div className="flex flex-row gap-4">
                        <FormikSelectGroup
                            formik={formik}
                            label="Select Quality"
                            name={`quality`}
                            options={generateQualityLabel(quality.docs)}
                            required
                        />
                        <FormikInputGroup
                            name="customer_commission"
                            fullWidth={true}
                            type="number"
                            label="Customer Commission"
                            formik={formik}
                            required
                        />
                        <FormikInputGroup
                            name="vendor_commission"
                            fullWidth={true}
                            type="number"
                            label="Vendor Commission"
                            formik={formik}
                            required
                        />
                    </div>
                    <div className="flex flex-row gap-4">
                        <FormikSelectGroup
                            formik={formik}
                            label="Commission Collection "
                            name={`isVendor`}
                            options={generateOptions({
                                array: LABEL_VALUES,
                                labelField: 'label',
                                valueField: 'value',
                            })}
                            required
                        />
                        <FormikInputGroup
                            name="quantity"
                            fullWidth={true}
                            type="number"
                            label="Quantity"
                            formik={formik}
                            required
                        />
                    </div>
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </div>
    );
};

export default CreatePurchaseOrder;
