import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import QueryString from 'qs';
import { toast } from 'react-toastify';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';

import PageWithCard from '../../components/infrastructure/PageWithCard';
import { authAxiosInstance } from '../../utils/axiosConfig';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import PaginationClassic from '../../components/pagination/PaginationClassic';
import telephoneLogo from '../../images/telephone.png';
import telephoneLogo2 from '../../images/telephone2.png';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import { MessageSquare, PhoneIncoming, PhoneOutgoing } from 'react-feather';

const PhoneBook = () => {
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [phonebook, setPhonebook] = useState({});
    console.log('phonebook', phonebook);
    const [linkOption, setLinkOption] = useState({});
    const [openMessageBox, setOpenMessageBox] = useState(false);
    const [scrollY, setScrollY] = useState(0)

    const dispatch = useDispatch();

    const fetchPhonebook = async (search = '') => {
        try {
            setLoading(true);
            const payload = {
                page,
                occurredDate: {
                    $gte: moment().subtract(2, 'days').startOf('day').valueOf(),
                    $lte: moment().endOf('day').valueOf(),
                },
            };
            if (search || search !== '') {
                payload['search'] = search;
            }
            console.log('fetch payload', payload);
            const string = QueryString.stringify(payload);
            const resp = await authAxiosInstance.get(`/phonebook?${string}`);
            console.log('resp', resp);
            setPhonebook(resp?.data?.data);
        } catch (error) {
            console.log('Error', error);
            toast.error('Failed to fetch phonebook data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPhonebook();
    }, [page]);

    useEffect(()=>{
        const handelScroll = ()=>{
            setScrollY(window.scrollY)
        }
        window.addEventListener('scroll', handelScroll);

        return ()=>{
            window.removeEventListener('scroll', handelScroll)
        }
    },[])

    const replacePlaceholder = (inputStr, replacementArray) => {
        let replacedStr = inputStr;
        for (let i = 0; i < replacementArray.length; i++) {
            replacedStr = replacedStr.replace('{#var#}', replacementArray[i]);
        }
        return replacedStr;
    };

    const debouncedSearch = useCallback(
        _.debounce(async (search) => {
            fetchPhonebook(search);
        }, 300),
        []
    );

    const groupByDate = (data) => {
        const grouped = data?.reduce((acc, ele) => {
            const occurredMoment = moment(ele.occurredAt, 'MMMM D, YYYY [at] hh:mma'); 
            const isToday = occurredMoment.isSame(moment(), 'day');
            const isYesterday = occurredMoment.isSame(
                moment().subtract(1, 'days'),
                'day'
            );
            const groupTitle = isToday
                ? 'Today'
                : isYesterday
                  ? 'Yesterday'
                  : occurredMoment.format('DD/MM/YYYY');

            if(!acc[groupTitle]){
                acc[groupTitle] = []
            }
            acc[groupTitle].push(ele);

            return acc;
        }, {});

        return Object.entries(grouped)
    };
    const calculateCallStatus = (status, time) => {
        if (status === 'missed' && time === 0) {
            return (
                <PhoneIncoming
                    color="#E71010"
                    style={{ fill: '#E71010' }}
                    size={20}
                />
            );
        } else if (status === 'outgoing' && time === 0) {
            return (
                <img src={telephoneLogo} alt="telephone" className="w-[24px]" />
            );
        } else if (status === 'outgoing' && time > 0) {
            return (
                <img
                    src={telephoneLogo2}
                    alt="telephone2"
                    className="w-[24px]"
                />
            );
        } else {
            return (
                <PhoneIncoming
                    color="#347D2F"
                    style={{ fill: '#347D2F' }}
                    size={20}
                />
            );
        }
    };

    const cols = [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col">
                        <span>{row?.original?.name}</span>
                        <span className="text-[11px]">
                            {row?.original?.phone}
                        </span>
                    </div>
                );
            },
            cellStye: {
                width: '60%',
            },
        },
        // {
        //     Header: 'Phone No.',
        //     accessor: 'phone',
        // },
        {
            Header: 'Time',
            accessor: 'occurredAt',
            Cell: ({ row }) => {
                const occurredMoment = moment(
                    row.original.occurredAt,
                    'MMMM D, YYYY [at] h:mma'
                );
                const date = occurredMoment.format('DD-MM');
                const time = occurredMoment.format('HH:mm');
                return (
                    <div className="flex flex-col">
                        <span>{date}</span>
                        <span>{time}</span>
                        <span className="hidden">
                            {row?.original?.occurredAt}
                        </span>
                    </div>
                );
            },
            cellStye: {
                width: '15%',
            },
        },
        {
            Header: 'Type',
            accessor: 'callStatus',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col">
                        <span>
                            {calculateCallStatus(
                                row?.original?.callStatus,
                                row?.original?.callDuration
                            )}
                        </span>
                        <span className="hidden">
                            {row?.original?.callStatus}
                        </span>
                    </div>
                );
            },
            cellStye: {
                width: '10%',
            },
        },
        // {
        //     Header: 'Duration',
        //     accessor: 'callDuration',
        // },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <span
                        className="cursor-pointer"
                        onClick={async (e) => {
                            e.stopPropagation();
                            const template = {
                                to: row?.original?.phone,
                                recipient_type: 'individual',
                                type: 'template',
                                template: {
                                    language: {
                                        policy: 'deterministic',
                                        code: 'en_US',
                                    },
                                    name: 'out_going_call_placed_but_not_attend',
                                    components: [],
                                },
                            };
                            const respData = await authAxiosInstance.post(
                                `/api/ifttt/manualText`,
                                template
                            );
                            console.log('respData', respData);
                            if (respData?.data?.success) {
                                toast.success(`${respData?.data?.message}`);
                            }
                        }}
                    >
                        {/* <img
                            src={messageLogo}
                            alt="messageLogo"
                            className="w-[25px] h-[25px]"
                        /> */}
                        <MessageSquare color="#3F51B5" />
                    </span>
                );
            },
            cellStye: {
                width: '15%',
            },
        },
    ];

    const phonebookData = useMemo(() => {
        if (phonebook && phonebook?.docs?.length) {
            return groupByDate(phonebook?.docs);
        } else return [];
    }, [phonebook]);
    console.log('phoneBook data==>>>', phonebookData)
    return (
        // <PageWithCard heading="Phonebook">
        //     <TableWithHeadingAndGlobalSearch
        //         columns={cols}
        //         data={phonebookData}
        //         loading={loading}
        //         searchFunction={debouncedSearch}
        //     />
        //     <PaginationClassic
        //         paginationDetails={phonebook}
        //         setPage={setPage}
        //     />
        // </PageWithCard>
        <div className="p-4 w-fill md:w-1/2 ">
            <h1 className="text-xl font-semibold mb-4">Phonebook</h1>
            {
                loading ? <ClipLoader /> :
                (
                    phonebookData.map(([dateTitle, values])=>(
                        <div className='' key={dateTitle}>
                            <div className="sticky top-[63px] bg-gray-200 text-gray-500 text-sm px-1 mx-auto z-60 text-center w-[15%] rounded-lg">
                            {dateTitle} 
                        </div>
                        <TableWithHeadingAndGlobalSearch 
                        columns={cols}
                        data={values}
                        loading={false}
                        searchFunction={debouncedSearch}
                        />
                        </div>
                    ))
                )
            }
            {/* <TableWithHeadingAndGlobalSearch
                columns={cols}
                data={phonebookData}
                loading={loading}
                searchFunction={debouncedSearch}
            /> */}
            <PaginationClassic
                paginationDetails={phonebook}
                setPage={setPage}
            />
        </div>
    );
};

export default PhoneBook;
