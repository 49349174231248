import React, { useEffect, useMemo, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import EventForm from './EventForm';
import { ChevronRight, Plus } from 'react-feather';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import AddCalenderTime from './AddCalenderTime';
import { ClipLoader } from 'react-spinners';
import ToggleButton from '../../components/ToggleButton/ToggleButton';
import QueryString from 'qs';

const convertToISO = (timeString, date) => {
    if (!timeString) return null;

    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const localDate = new Date(date);
    localDate.setHours(hours, minutes, seconds, 0);
    const isoString = localDate.toISOString();

    return isoString;
};

const localizer = momentLocalizer(moment);

// const generateHolidaysForYear = () => {
//     const holidays = [];
//     const holidayDates = ['2024-01-01', '2024-12-25', '2024-07-04'];

//     holidayDates.forEach((day) => {
//         holidays.push({
//             title: 'Holiday',
//             start: moment(day).toDate(),
//             end: moment(day).endOf('day').toDate(),
//             allDay: true,
//             type: 'holiday',
//         });
//     });
//     return holidays;
// };

const generateLunchBreaksForYear = (startTime, endTime) => {
    const lunchBreaks = [];
    const startOfYear = moment().startOf('year');
    const endOfYear = moment().add(1, 'year').endOf('year');

    for (
        let date = startOfYear;
        date.isBefore(endOfYear);
        date.add(1, 'days')
    ) {
        if (date.day() === 0) continue; //0 means sunday and 6 means saturday

        const startMoment = moment(convertToISO(startTime, date)).toDate();
        const endMoment = moment(convertToISO(endTime, date)).toDate();

        lunchBreaks.push({
            title: 'Lunch Break',
            start: startMoment,
            end: endMoment,
            allDay: false,
            type: 'lunchBreak',
        });
    }
    return lunchBreaks;
};

const EventCalender = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [timeData, setTimeData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [lunchBreaks, setLunchBreaks] = useState([]);
    const [mobileEvents, setMobileEvents] = useState([]);
    const [isBusyMode, setIsBusyMode] = useState('no');
    const [isMeetingMode, setIsMeetingMode] = useState('no');

    // const holidays = generateHolidaysForYear();
    const [events, setEvents] = useState([]);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };
    const handleSelectSlot = async ({ start, end, action }) => {
        const isFullDay = events.some(
            (event) =>
                moment(start).isSame(event.start, 'day') &&
                event.allDay === true
        );
        if (isFullDay) {
            alert('No Extra event add on full day event.');
            return;
        }
        const isHoliday = events.some(
            (event) =>
                moment(start).isSame(event.start, 'day') &&
                event.type === 'holiday'
        );
        if (isHoliday) {
            alert(
                'This day is already a holiday. No events can be created on holidays.'
            );
            return;
        }
        const isSunday = moment(start).day() === 0;
        if (isSunday) {
            alert('Cannot create events on Sundays.');
            return;
        }
        const isDuringLunch = lunchBreaks.some(
            (breakEvent) =>
                moment(start).isBetween(
                    moment(breakEvent.start),
                    moment(breakEvent.end),
                    undefined,
                    '[)'
                ) ||
                moment(end).isBetween(
                    moment(breakEvent.start),
                    moment(breakEvent.end),
                    undefined,
                    '(]'
                )
        );
        if (isDuringLunch) {
            alert('Cannot create events during lunch breaks.');
            return;
        }
        setSelectedEvent({ start, end, title: '', action });
        setIsModalOpen(true);
        setIsEditing(false);
    };
    const handleSelectEvent = async (event) => {
        // const isHoliday = event.type === 'holiday' ? true : false;
        const isLunch = event.type === 'lunchBreak' ? true : false;
        // if (isHoliday) {
        //     alert('Cannot edit Holidays.');
        //     return;
        // }
        if (isLunch) {
            alert('Cannot edit lunch breaks.');
            return;
        }
        setSelectedEvent(event);
        setIsModalOpen(true);
        setIsEditing(true);
    };

    const eventPropGetter = (event) => {
        if (event.type === 'lunchBreak') {
            return {
                style: {
                    backgroundColor: '#E6E6E6',
                    border: 'none',
                    borderRadius: '4px',
                    // padding: '5px',
                    fontSize: '10px',
                    color: '#000',
                },
            };
        } else if (event.type === 'holiday') {
            return {
                style: {
                    backgroundColor: '#F3D2CC',
                    color: '#000',
                    border: 'none',
                    borderRadius: '4px',
                    fontSize: '10px',
                    // padding: '5px',
                },
            };
        }
        return {
            style: {
                backgroundColor: '#5daae9',
                color: 'fffff',
                border: 'none',
                borderRadius: '4px',
                fontSize: '14px',
                // padding: '5px',
            },
        };
    };

    const dayPropGetter = (date) => {
        const isSunday = moment(date).day() === 0;
        const isHoliday = events.some(
            (event) =>
                moment(date).isSame(event.start, 'day') &&
                event.type === 'holiday'
        );
        const isAllDay = events.some(
            (event) =>
                moment(date).isSame(event.start, 'day') &&
                event.type === 'meeting' &&
                event.allDay === true
        );
        if (isSunday || isHoliday) {
            return {
                style: {
                    backgroundColor: isHoliday ? '#F3D2CC' : '#E6E6E6',
                    cursor: 'not-allowed',
                },
            };
        }
        if (isAllDay) {
            return {
                style: {
                    backgroundColor: '#5daae9',
                    cursor: 'not-allowed',
                },
            };
        }
    };

    const eventData = useMemo(() => {
        return (events || [])
            .map((ele) => ({
                ...ele,
                start: moment(ele.start).toDate(),
                end: moment(ele.end).toDate(),
            }))
            .filter((item) => {
                if (item.type === 'lunchBreak') {
                    const isSameDayHoliday = events.some(
                        (holiday) =>
                            holiday.type === 'holiday' &&
                            moment(holiday.start).isSame(
                                moment(item.start),
                                'day'
                            )
                    );
                    return !isSameDayHoliday;
                }
                return true;
            });
    }, [events]);

    const calendarMin = useMemo(() => {
        return timeData?.workStartTime
            ? moment(timeData?.workStartTime, 'HH:mm:ss').toDate()
            : moment('10:30:00', 'HH:mm:ss').toDate();
    }, [timeData]);

    const calendarMax = useMemo(() => {
        return timeData?.workEndTime
            ? moment(timeData?.workEndTime, 'HH:mm:ss').toDate()
            : moment('20:30:00', 'HH:mm:ss').toDate();
    }, [timeData]);

    const fetchEvents = async () => {
        try {
            const resp = await authAxiosInstance.get(`/calenderEvent`);
            const allEvents = resp?.data?.data;
            setEvents([...lunchBreaks, ...allEvents]);
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    };

    const fetchCalenderTimes = async () => {
        try {
            const resp = await authAxiosInstance.get(`/calenderTimes`);
            const fetchTimes = resp?.data?.data[0];
            setTimeData(fetchTimes);
            const lunchData = generateLunchBreaksForYear(
                fetchTimes?.lunchStartTime,
                fetchTimes?.lunchEndTime
            );
            setLunchBreaks(lunchData?.length > 0 ? lunchData : []);
        } catch (error) {
            console.error('Error fetching calendar times:', error);
        }
    };

    const fetchModes = async () => {
        try {
            setIsLoading(true);
            const resp = await authAxiosInstance.get('/mode');
            const modeData = resp?.data?.data;
            modeData.forEach((mod) => {
                if (mod?.modeType === 'meeting') {
                    setIsMeetingMode(mod?.isBusy);
                }
                if (mod?.modeType === 'busy') {
                    setIsBusyMode(mod?.isBusy);
                }
            });
            setIsLoading(false);
        } catch (error) {
            console.log('Error', error);
            toast.error('Failed to fetch modes');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAllData = async () => {
        try {
            const [allEvents, calenderTimes] = await Promise.all([
                authAxiosInstance.get(`/calenderEvent`),
                authAxiosInstance.get(`/calenderTimes`),
            ]);

            const events = allEvents?.data?.data;
            const fetchTimes = calenderTimes?.data?.data[0];

            setTimeData(fetchTimes);
            const lunchData = generateLunchBreaksForYear(
                fetchTimes?.lunchStartTime,
                fetchTimes?.lunchEndTime
            );
            setLunchBreaks(lunchData?.length > 0 ? lunchData : []);

            setEvents([...events, ...(lunchData || [])]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchCurrentMonthEvents = async () => {
        try {
            const startOfMonth = moment().startOf('month').toISOString();
            const endOfMonth = moment().endOf('month').toISOString();

            const query = {
                start: startOfMonth,
                end: endOfMonth,
                sort: { start: 1 },
            };
            const string = QueryString.stringify(query);
            const resp = await authAxiosInstance.get(
                `/calenderEvent/currentMonth?${string}`
            );

            if (resp?.data?.success) {
                const fetchedEvents = resp?.data?.data;
                const filteredEvents = fetchedEvents.filter(
                    (ele) => ele.type !== 'holiday' && ele.type !== 'lunchBreak'
                );
                setMobileEvents(filteredEvents);
            }
        } catch (error) {
            console.log('error', error);
            toast.error('Failed to fetch current month events');
        }
    };

    useEffect(() => {
        fetchAllData();
        fetchCurrentMonthEvents();
    }, []);

    useEffect(() => {
        setEvents((prevEvents) => {
            const nonLunchEvents = prevEvents.filter(
                (event) => event.type !== 'lunchBreak'
            );
            return [...nonLunchEvents, ...lunchBreaks];
        });
    }, [lunchBreaks]);

    useEffect(() => {
        fetchModes();
    }, []);
    return (
        <>
            <EventForm
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
                isEditing={isEditing}
                events={events}
                fetchEvents={fetchEvents}
            />
            <AddCalenderTime
                isTimeModalOpen={isTimeModalOpen}
                setIsTimeModalOpen={setIsTimeModalOpen}
                fetchCalenderTimes={fetchCalenderTimes}
            />
            <div className="flex flex-col md:flex-row gap-2 px-2 py-2 h-[90vh] bg-gray-100">
                <div className="w-full md:w-[20%] relative">
                    <SecondaryButton
                        className="w-full"
                        onClick={toggleDropdown}
                    >
                        <Plus
                            size={20}
                            className={`mr-1 transition-transform duration-300 ${
                                showDropdown ? '-rotate-45' : 'rotate-90'
                            }`}
                        />{' '}
                        New Event
                    </SecondaryButton>
                    {showDropdown && (
                        <div className="absolute top-10 left-0 w-full mt-2 bg-white shadow-lg border rounded-md z-50">
                            <SecondaryButton
                                className="flex items-center justify-between w-full text-left px-4 py-2 hover:bg-gray-100"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsTimeModalOpen(true);
                                    setShowDropdown(false);
                                }}
                            >
                                Add Calendar Time <ChevronRight size={14} />
                            </SecondaryButton>
                            <SecondaryButton
                                className="flex items-center justify-between w-full text-left px-4 py-2 hover:bg-gray-100"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsModalOpen(true);
                                    setIsEditing(false);
                                    setShowDropdown(false);
                                }}
                            >
                                Add Event <ChevronRight size={14} />
                            </SecondaryButton>
                        </div>
                    )}
                    <button className="btn border-slate-200 flex items-center justify-between w-full text-left py-[0.5px]">
                        Meeting Mode
                        {isLoading ? (
                            <ClipLoader size={32} />
                        ) : (
                            <ToggleButton
                                className="mt-1"
                                toggled={isMeetingMode === 'yes'}
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    let endTime;
                                    if (isMeetingMode === 'no') {
                                        // const currentDateTime = moment();
                                        const userHours = prompt(
                                            'Please enter meeting duration in hours:'
                                        );
                                        console.log('user hour', userHours);
                                        if (
                                            userHours &&
                                            !isNaN(userHours) &&
                                            Number(userHours) > 0
                                        ) {
                                            // endTime = currentDateTime
                                            //     .add(Number(userHours), 'hours')
                                            //     .utc()
                                            //     .toDate();
                                            endTime = userHours
                                        } else {
                                            toast.error(
                                                'Invalid input. Please enter a valid number of hours.'
                                            );
                                            return;
                                        }
                                    }                                    
                                    
                                    const newMode =
                                        isMeetingMode === 'no' ? 'yes' : 'no';
                                    const endTimeUpdate =
                                        isMeetingMode === 'no' ? endTime : null;

                                    const data = {
                                        isBusy: newMode,
                                        modeType: 'meeting',
                                        hours: endTimeUpdate,
                                    };

                                    try {
                                        const resp =
                                            await authAxiosInstance.patch(
                                                '/mode',
                                                data
                                            );
                                        if (resp?.data?.success) {
                                            setIsMeetingMode(newMode);
                                        }
                                    } catch (error) {
                                        console.error(
                                            'Error toggling mode:',
                                            error
                                        );
                                        toast.error('Failed to update mode');
                                    }
                                }}
                            />
                        )}
                    </button>
                    <button className="btn border-slate-200 flex items-center justify-between w-full text-left py-[0.5px]">
                        Busy Mode
                        {isLoading ? (
                            <ClipLoader size={32} />
                        ) : (
                            <ToggleButton
                                className="mt-1"
                                toggled={isBusyMode === 'yes'}
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    let endTime;
                                    if (isBusyMode === 'no') {
                                        // const currentDateTime = moment();
                                        const userHours = prompt(
                                            'Please enter busy duration in hours:'
                                        );
                                        console.log('user hour', userHours);
                                        if (
                                            userHours &&
                                            !isNaN(userHours) &&
                                            Number(userHours) > 0
                                        ) {
                                            // endTime = currentDateTime
                                            //     .add(Number(userHours), 'hours')
                                            //     .utc()
                                            //     .toDate();
                                            endTime = userHours
                                        } else {
                                            toast.error(
                                                'Invalid input. Please enter a valid number of hours.'
                                            );
                                            return;
                                        }
                                    }
                                    const newMode =
                                        isBusyMode === 'no' ? 'yes' : 'no';
                                    const endTimeUpdate =
                                        isBusyMode === 'no' ? endTime : null;

                                    const data = {
                                        isBusy: newMode,
                                        modeType: 'busy',
                                        hours: endTimeUpdate,
                                    };
                                    try {
                                        const resp =
                                            await authAxiosInstance.patch(
                                                '/mode',
                                                data
                                            );
                                        if (resp?.data?.success) {
                                            setIsBusyMode(newMode);
                                        }
                                    } catch (error) {
                                        console.error(
                                            'Error toggling mode:',
                                            error
                                        );
                                        toast.error('Failed to update mode');
                                    }
                                }}
                            />
                        )}
                    </button>
                </div>
                <div
                    className="hidden md:block md:w-[80%] h-full bg-white"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Calendar
                        localizer={localizer}
                        events={eventData}
                        startAccessor="start"
                        endAccessor="end"
                        selectable
                        onSelectSlot={handleSelectSlot}
                        onSelectEvent={handleSelectEvent}
                        min={calendarMin}
                        max={calendarMax}
                        excludeDays={[0]}
                        eventPropGetter={eventPropGetter}
                        dayPropGetter={dayPropGetter}
                        // style={{ height: '100%' }}
                        views={['month', 'week', 'day']}
                    />
                </div>
                <div className="w-full md:hidden mb-6 h-[350px] overflow-x-auto">
                    {mobileEvents.length > 0 ? (
                        mobileEvents.map((eve) => (
                            <div
                                key={eve._id}
                                className="rounded-md border py-2 px-3 mt-1"
                                style={{
                                    backgroundColor: '#5daae9',
                                    color: '#ffffff',
                                }}
                            >
                                <h5
                                    className="py-1 underline underline-offset-2"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSelectEvent(eve);
                                    }}
                                >
                                    {eve.title}
                                </h5>
                                <p>{`Date : ${moment(eve.start).format('DD-MM-YYYY')} `}</p>
                                <div>{`Time : ${moment(eve.start).format('hh:mm a')} - ${moment(eve.end).format('hh:mm a')}`}</div>
                            </div>
                        ))
                    ) : (
                        <div>'No Events For This Month</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default EventCalender;
